import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import Certificates from "./routes/Certificates";
import Blogs from "./routes/Blogs";

function App() {
  return (
    
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/certificates" element={<Certificates/>}/>
      <Route path="/blogs" element={<Blogs/>}/>  
    </Routes>
  );
}

export default App;
