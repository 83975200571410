import React, { useState } from 'react'
import './RoutesCertificate.css'
import LeftArrow from '../../assets/leftarrow.png'
import RightArrow from '../../assets/rightarrow.png'
import { certificateData } from '../../data/certificateData'
import {FaFacebook, FaTwitter, FaInstagram, FaLinkedin} from 'react-icons/fa'

const RoutesCertificate = () => {

    const [selected, setSelected] = useState(0);

    const cLength = certificateData.length;

  return (
    <div className="rc-certification">
        <div className="rc-certification-content">
            <div className="rc-social-content">
            <div className="rc-social">
            <a href="">
                        <FaTwitter/>
                    </a>
                    <a href="">
                        <FaFacebook/>
                    </a>
                    <a href="">
                        <FaInstagram/>
                    </a>
                    <a href="">
                        <FaLinkedin/>
                    </a>
                    
            </div>
            <div className="rc-social-divider"></div>
                <div className="rc-social-text">
                    <h4>Follow me on</h4>
                </div>
                </div>
            {/* left side */}
            <div className="rc-left">
                <div className="rc-left-content">
                    <div className="rc-divider"></div>
                    <h2>{certificateData[selected].certificateName}</h2>
                </div>
                <div className="rc-permanent">
                    <p>{certificateData[selected].review}</p>
                </div>
                <div className="rc-author">
                    <h4>{certificateData[selected].name}</h4>
                    <h4>Published: {certificateData[selected].date}</h4>
                </div>
            </div>
            {/* right side */}
            <div className="rc-right">
            <div className='rc-arrows'>
                <img
                onClick={()=>{
                    selected=== 0 ? setSelected(cLength -1):
                    setSelected((prev) => prev -1)
                }}
                src={LeftArrow} alt="" />
                <img
                onClick={()=>{
                    selected=== cLength -1 ? setSelected(0) :
                    setSelected((prev) => prev +1)
                }}
                src={RightArrow} alt="" />
            </div>
                <div className="rc-right-content">
                <div className="rc-certificates1"></div>
                    <div className="rc-wbg">
                        <div className="rc-certificates">
                            <div></div>
                            <div></div>
                            <img src={certificateData[selected].image} alt="" />
                            <img src={certificateData[1].image} alt="" />
                            <img src={certificateData[2].image} alt="" />
                        </div>
                    </div>
                    
                </div>
                <div className="rc-divider1"></div>
            </div>
           
        </div>
        
    </div>

  )
}

export default RoutesCertificate
