import React from 'react'
import './BlogsHero.css'
import SyndromeAuthor from '../../../assets/syndromeauth.png'
import {FaFacebook, FaTwitter, FaInstagram, FaLinkedin} from 'react-icons/fa'

const BlogsHero = () => {
  return (
    <div className='blogshero'>
        <div className="bbc-social-content">
        <div className="bc-social">
            <a href="">
                        <FaTwitter/>
                    </a>
                    <a href="">
                        <FaFacebook/>
                    </a>
                    <a href="">
                        <FaInstagram/>
                    </a>
                    <a href="">
                        <FaLinkedin/>
                    </a>
                    
            </div>
            <div className="bbc-social-divider"></div>
                <div className="bbc-social-text">
                    <h4>Follow me on</h4>
                </div>
        </div>
      <div className="blogshero-content">
        <div className="blogshero-left">
            <div className="syndrome-text">
                <h2>Elsberg</h2>
                <h2>Syndrome</h2>
            </div>
            <div className="syn-text">
                <p>It is a long established fact that a reader will be distracted by<br/>
                the readable content of a page when looking at its layout. The<br/>
                point of using lorem ipsum is that it has a more-or-less normal<br/>
                distribution</p>
            </div>
            <div className="syn-author">
                <h5>Dr. Ahmed Fallata</h5>
                <h5>Published: June 8, 2022</h5>
            </div>
        </div>
        <div className="blogshero-right">
            <img src={SyndromeAuthor} alt="" />
        </div>
      </div>
    </div>
  )
}

export default BlogsHero
