import React from 'react'
import BlogHeading1 from '../components/BlogsComponent/BlogHeading1/BlogHeading1'
import BlogsHeading2 from '../components/BlogsComponent/BlogsHeading2/BlogsHeading2'
import BlogsHeading3 from '../components/BlogsComponent/BlogsHeading3/BlogsHeading3'
import BlogsHeading4 from '../components/BlogsComponent/BlogsHeading4/BlogsHeading4'
import BlogsHero from '../components/BlogsComponent/BlogsHero/BlogsHero'
import MoreBlogs from '../components/BlogsComponent/MoreBlogs/MoreBlogs'
import Footer from '../components/Footer/Footer'
import Help from '../components/Help/Help'
import SecondNavbar from '../components/SecondNavbar/SecondNavbar'

const Blogs = () => {
  return (
    <>
    <SecondNavbar/>
    <BlogsHero/>
    <BlogHeading1/>
    <BlogsHeading2/>
    <BlogsHeading3/>
    <BlogsHeading4/>
    <MoreBlogs/>
    <Help/>
    <Footer/>
    </>
  )
}

export default Blogs