import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './SecondNavbar.css'
import {Link as LinkRoll} from 'react-scroll'


const SecondNavbar = () => {

  return (
   
    <nav className="secondnavbar">
        <ul className="s-nav-links">
        <Link to='/'><li>Home</li></Link>
            {/* <LinkRoll activeClass='active'
                      to='About'
                      spy={true}
                      duration={500}
                      smooth={true}>
                        <li>About</li>
                      </LinkRoll>
            <LinkRoll activeClass='active'
                      to='Education'
                      spy={true}
                      smooth={true}><li>Education</li></LinkRoll>
            <LinkRoll activeClass='active'
                      to='Specialization'
                      spy={true}
                      smooth={true}><li>Specialization</li></LinkRoll>
            <LinkRoll activeClass='active'
                      to='Career'
                      spy={true}
                      smooth={true}><li>Career</li></LinkRoll> */}
            <Link to='/certificates'><li>Certificates</li></Link>
            <Link to='/blogs'><li>Blogs</li></Link>
            <LinkRoll 
            activeClass='active'
            to='Contact'
            spy={true}
            smooth={true}><li>Contact</li></LinkRoll>
        </ul>
    </nav>
  
    
  )
}

export default SecondNavbar
